import React from 'react'
import './insideheader.css'
import ReactMarkdown from 'react-markdown'


const Facilities = ({ itemControl, itemHeading, itemContent, itemImage }) => (

  <div className="row gap-y align-items-center">
    <div className="col-md-6 ml-auto">
      <ReactMarkdown source={itemHeading} escapeHtml={false} />
      <ReactMarkdown source={itemContent} escapeHtml={false} />
    </div>
    <div className={itemControl}>
      <img className="rounded shadow-2" src={itemImage} alt="..." />
    </div>
  </div>




)

export default Facilities
