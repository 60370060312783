import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import InsideHeader from '../components/insideheader'
import Facilities from '../components/facilities'
import SingleColumn from '../components/singlecolumn'
import ReactMarkdown from 'react-markdown'
import Helmet from 'react-helmet'
import Metatags from '../components/Metatags'

export default ({ data }) => {
  const page = data.dataJson
  const hideStyle = "none"
  var iControl = 1
  var itemControl="col-md-6 order-md-first"
  var itemControl1="col-md-6"
  return (

      <Layout>
      <Metatags
               title={page.meta.metatitle}
               keywords={page.meta.metakeywords}
               description={page.meta.metadescription}
               thumbnail={page.meta.metaimage}
               url={page.meta.metaurl}
               pathname={page.meta.metapathname}
           />
          <InsideHeader pageTitle={page.title} pageSubTitle={page.subTitle} pageBackground={page.titleBackground}/>
          <main className="main-content">
              <section className="section">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-10 mx-auto">

                          {page.contents.map((item, index) => (
                              <>
                                <span style={{display: 'none'}}>
                                  {iControl ?  iControl=0 : iControl=1}
                                </span>
                                {item.content ? iControl ? <Facilities itemControl={itemControl} itemHeading={item.heading} itemContent={item.content} itemImage={item.image}/> : <Facilities itemControl={itemControl1} itemHeading={item.heading} itemContent={item.content} itemImage={item.image}/> : <SingleColumn itemHeading={item.heading} />}
                              </>
                          ))}

                          </div>
                      </div>
                  </div>
              </section>
          </main>
      </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
        title
        subTitle
        titleBackground
        contents {
           heading
           image
           content
           heading
         }
         meta {
             metadescription
         metakeywords
         metatitle
         metaimage
         metaurl
         metapathname
         }
    }
  }
`
